.info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    border-radius: 5px;
  }
  
  .legend {
    text-align: left;
    line-height: 18px;
    color: #555;
  }